const Navbar = () => {
  return (
    <>
      <div className="container-fluid">
        <nav className="d-flex w-100 bg-success align-items-center justify-content-between">
          <h1 className="nav__brand mx-5">LOGO</h1>

          <ul className="nav__list p-4 w-50 d-flex justify-content-around">
            <li className="nav__list__item">link1</li>
            <li className="nav__list__item">link2</li>
            <li className="nav__list__item">link3</li>
            <li className="nav__list__item">link4</li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
