import Section from "./Section";

const Main = () => {
  const sectionInfo = {
    one: "1",
    two: "2",
    three: "3",
  };

  return (
    <>
      <main className="main">
        <Section number={sectionInfo} />
        <Section number={sectionInfo} />
        <Section number={sectionInfo} />
      </main>
    </>
  );
};

export default Main;
