

const Section = () => {
   return (
      <>
          <section className="section">
             <h1>SECTION 1</h1>
          </section>
      </>
   );
};

export default Section;