const Footer = () => {
   return (
     <>
       <footer className="footer p-3 bg-primary">
         <p className="text-warning">{new Date().getFullYear()}</p>
       </footer>
     </>
   );
 };
 
 export default Footer;
 